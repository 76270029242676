<template>
  <div>
    <PageHeader
      title="Création / Modification d'une question"
    />

    <PageContent>
      <div class="m-auto max-w-6xl shadow sm:rounded-md">
        <div class="space-y-6 bg-white sm:p-6">
          <div class="grid grid-cols-4 gap-6">
            <div v-if="question && question.uuid" class="col-span-4 flex justify-between">
              <label>Ajoutée le {{ question.created_at | toDateTime }}</label>
              <label>Dernière modification le {{ question.updated_at | toDateTime }}</label>
            </div>

            <div
              v-if="domain && roles.some(role => ['admin'].includes(role)) && domain.uuid === '792a622b-fa4c-44c7-92fa-b1b0fd37336a'"
              class="col-span-4 flex items-center gap-3"
            >
              <label for="is-global">Question commune à tous les domaines</label>
              <t-toggle name="is-global" v-model="question.is_global"></t-toggle>
            </div>

            <div class="col-span-4 md:col-span-2">
              <t-input-group label="Type">
                <t-select
                  placeholder="Choisissez un type"
                  :options="types.map(type => ({ value: type, label: type.name }))"
                />
              </t-input-group>
            </div>

            <div class="col-span-4 md:col-span-2">
              <t-input-group label="Catégorie">
                <t-select
                  placeholder="Choisissez une Catégorie"
                  :options="categories.map(category => ({ value: category, label: category.label }))"
                />
              </t-input-group>
            </div>

            <div class="col-span-4">
              <t-input-group label="Titre">
                <t-input
                  v-model="question.title"
                  type="text"
                />
              </t-input-group>
            </div>

            <div class="col-span-4">
              <t-input-group label="Réponse">
                <quill-editor ref="myQuillEditor" v-model="question.response" :options="quillOptions" />
              </t-input-group>
            </div>

            <div class="col-span-4">
              <t-input-group label="Mots-clés">
                <b-taginput name="keywords" v-model="question.keywords" attached icon="fas fa-tag" placeholder="Ajouter un tag"></b-taginput>
              </t-input-group>
            </div>

            <div class="col-span-2">
              <t-input-group label="Documents">
                <b-upload class="block" multiple @input="filesAdded" v-model="filesToAdd">
                  <a class="button is-primary">
                    <b-icon icon="upload"></b-icon>
                    <span>Ajouter des documents</span>
                  </a>
                </b-upload>
              </t-input-group>
            </div>

            <div class="col-span-2">
              <ul class="block">
                <li v-for="file in question.files" :key="file.id">
                  <div class="flex gap-1 justify-between items-center text-left">
                    <a :href="`${file.url}`" target="_blank">
                      <b-icon icon="fas fa-paperclip"></b-icon>
                    </a>
                    <span class="flex-grow">{{ file.name }}</span>
                    <span @click="removeFile(file)">
                      <b-icon
                        icon="times"
                        size="is-small">
                      </b-icon>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 flex gap-3 justify-between">
          <div class="flex gap-3 items-center">
            <label for="active">Actif</label>
            <t-toggle class="ml" v-model="question.is_enabled"></t-toggle>
          </div>
          <div class="flex gap-3">
            <t-button class="m-0" @click.prevent="deleteQuestion" variant="error">Supprimer</t-button>
            <t-button @click.prevent="updateQuestion">Sauvegarder</t-button>
          </div>
        </div>
      </div>
    </PageContent>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { quillEditor } from 'vue-quill-editor'
import { http } from '@/services/api.service'
import { mapGetters } from 'vuex'

import PageHeader from '@/components/PageHeader'
import PageContent from '@/components/PageContent.vue'

export default {
  name: 'app-question',
  components: {
    quillEditor,
    PageHeader,
    PageContent
  },
  computed: {
    ...mapGetters([
      'roles',
      'user',
      'domain'
    ])
  },
  data () {
    return {
      quillOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic'],
            [
              // { list: 'ordered' },
              { list: 'bullet' }
            ],
            ['link']
          ]
        }
      },
      categories: [],
      types: [],
      question: {},
      storageUrl: process.env.VUE_APP_STORAGE_URL,
      filesToAdd: []
    }
  },
  mounted () {
    this.getQuestion()
    this.getCategories()
    this.getTypes()
  },
  methods: {
    getQuestion () {
      http.get(`/faq/${this.$route.params.uuid}`).then(res => {
        this.question = res.data
        if (
          this.domain &&
          this.domain.uuid === '792a622b-fa4c-44c7-92fa-b1b0fd37336a'
        ) {
          console.log('true')
          this.question.is_global = true
        }
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Nos serveurs rencontrent des difficultés à récupérer les informations concernant cette question',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    },
    getCategories () {
      http.get('/faq/category').then(res => {
        this.categories = res.data
      }).catch(error => {
        console.log(error)
      })
    },
    getTypes () {
      http.get('/faq/type').then(res => {
        this.types = res.data
      }).catch(error => {
        console.log(error)
      })
    },
    updateQuestion () {
      http.put(`/faq/${this.question.uuid}`, { ...this.question }).then(res => {
        this.$buefy.notification.open({
          duration: 3000,
          message: 'La question a été mise à jour avec succès !',
          position: 'is-bottom-left',
          type: 'is-success',
          hasIcon: true
        })
        this.$router.push({ path: '/app/questions' })
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3500,
          message: 'Nos serveurs rencontrent des difficultés à mettre à jour cette question',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    },
    deleteQuestion () {
      this.$buefy.dialog.confirm({
        title: 'Suppression',
        message: `Êtes vous sur de vouloir supprimer cette question ?`,
        cancelText: 'Annuler',
        confirmText: 'Confirmer',
        type: 'is-danger',
        onConfirm: () => {
          http.delete(`/faq/${this.question.uuid}`).then(res => {
            this.$buefy.notification.open({
              duration: 3000,
              message: 'La question a été supprimé avec succès !',
              position: 'is-bottom-left',
              type: 'is-success',
              hasIcon: true
            })
            this.$router.push({ path: '/questions' })
          }).catch(err => {
            console.log(err)
            this.$buefy.notification.open({
              duration: 3000,
              message: 'Nos serveurs rencontrent des difficultés à supprimer cette question',
              position: 'is-bottom-left',
              type: 'is-warning',
              hasIcon: true,
              icon: 'server'
            })
          })
        }
      })
    },
    getImgUrl () {
      return `${this.storageUrl}/thumbnails/${this.question.thumbnail}`
    },
    uploadThumbnail (file) {
      const formData = new FormData()
      if (file) {
        formData.append('thumbnail', file)
      }
      http.post(`/faq/${this.question.uuid}/thumbnail`, formData).then(({ data }) => {
        console.log(data)
        this.question.thumbnail = data
      }).catch(err => {
        console.log(err)
      })
    },
    filesAdded (files) {
      const formData = new FormData()
      this.filesToAdd.map(doc => {
        formData.append('files[]', doc)
      })
      http.post(`/faq/${this.question.uuid}/file`, formData).then(({ data }) => {
        this.question.files = [...this.question.files, ...data]
        this.filesToAdd = []
      }).catch(err => {
        this.filesToAdd = []
        console.log(err)
      })
    },
    removeThumbnail () {
      http.delete(`/faq/${this.question.uuid}/thumbnail`).then(({ data }) => {
        this.question.thumbnail = null
      }).catch(err => {
        console.log(err)
      })
    },
    removeFile (file) {
      http.delete(`/faq/${this.question.uuid}/file/${file.uuid}`).then(({ data }) => {
        console.log(data)
        this.question.files = this.question.files.filter(doc => doc.uuid !== file.uuid)
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss">
.thumbnail-preview {
  width: 64px;
  height: 64px;
  box-shadow: 1px 2px 5px 0 #999;
  border-radius: 2px;
}
</style>
